import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import LowLoadingEdge from '../images/b-class/highlights/low-loading-edge.jpeg'
import CovnenientEntry from '../images/b-class/highlights/convenient-entry.jpeg'
import FoldingRearSeats from '../images/b-class/highlights/folding-rear-seats.jpeg'

import HeaderImage from '../images/b-class/header-b-class.jpeg'

import InterieurImage from '../images/b-class/gallery/interieur-teaser-b-class.jpeg'
import ExterieurImage from '../images/b-class/gallery/exterieur-teaser-b-class.jpeg'

import InterieurImageGallery1 from '../images/b-class/gallery/gallery1-interieur-b-class.jpeg'
import InterieurImageGallery2 from '../images/b-class/gallery/gallery2-interieur-b-class.jpeg'
import InterieurImageGallery3 from '../images/b-class/gallery/gallery3-interieur-b-class.jpeg'

import ExterieurImageGallery1 from '../images/b-class/gallery/gallery1-exterieur-b-class.jpeg'
import ExterieurImageGallery2 from '../images/b-class/gallery/gallery2-exterieur-b-class.jpeg'
import ExterieurImageGallery3 from '../images/b-class/gallery/gallery3-exterieur-b-class.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
]

const BClass = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Die neue B-Klasse. Für alles was kommt.</H1>
      <p>
        So praktisch wie immer, so schick wie noch nie. Die neue Mercedes-Benz
        B-Klasse zeigt sich aufregend und sportlich. Sie sieht dynamischer aus
        als der Vorgänger, fährt sich agiler und bietet dabei mehr Komfort.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>So dynamisch, komfortabel und sicher wie nie.</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={LowLoadingEdge}
          title="Niedrige Ladekante"
          headline="Niedrige Ladekante."
          description="Viel Raum für höchsten Komfort: In der neuen B-Klasse beginnt für Sie das komfortable Beladen schon bei der niedrigen Ladekante. Der Laderaum lässt sich zudem ganz leicht der Größe Ihrer Vorhaben anpassen, denn die Fondsitzanlage ist extrem flexibel: Sie können deren Lehne in der Neigung verstellen oder geteilt umklappen. Dann stehen Ihnen bis zu 1.540 Liter Laderaum zur Verfügung. Dank der besonders breiten Öffnung passt selbst sperriges Gepäck ganz bequem hinein."
        />
        <ImageBox
          imagesrc={CovnenientEntry}
          title="Bequemer Einstieg"
          headline="Bequemer Einstieg."
          description="Dank der leicht erhöhten Sitzposition müssen Sie in die neue B-Klasse weder auf- noch absteigen. Sie gleiten völlig mühelos hinein. Zudem haben Sie das Verkehrsgeschehen optimal im Blick, was insbesondere in der Stadt das Fahren spürbar angenehmer macht. Auch die großen Fensterflächen verbessern die Rundumsicht."
        />
        <ImageBox
          imagesrc={FoldingRearSeats}
          title="Klappbare Fondsitze"
          headline="Geteilt klappbare Fondsitze."
          description="Für große Familien und kleine Abenteuer: Die neue B-Klasse ist auf fast alles im Leben bestens vorbereitet. Zum Beispiel mit einem variablen Ladeboden oder mit einer Fondsitzlehne, die sich in der Neigung verstellen lässt. Für wahlweise mehr Laderaum oder mehr Beinfreiheit. Zudem sind die Fondsitzlehnen geteilt klappbar, auf Wunsch sogar dreigeteilt. Damit können Sie auch sperriges Ladegut bequem und vor allem sicher verstauen."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default BClass
